<template>
  <v-dialog
    v-model="showDialog"
    @click:outside="closeDialog"
    width="1000px"
  >
    <v-card>
      <v-card-title class=" justify-space-between align-center">
        <div>
            <p class="title-dialog">{{ $t('invitationsAndCoupons.editInvitation') }}</p>
        </div>
        <div>
          <p class="id-item">{{ id }}</p>
        </div>
        </v-card-title>
      <v-card-text>
        <v-row class="px-10 pt-5 pb-0">
          <v-col :cols="4" class="pr-8 list-item">
            <div>
              <span>{{ $t('common.title') }}</span>
              <br>
              <span>{{ name }}</span>
            </div>
            <div class="mt-4">
              <span>{{ $t('common.numberOfSheets') }}</span>
              <br>
              <span>{{issuedCount}}</span>
            </div>
            <div class="mt-4">
              <span>{{ $t('common.facility') }}</span>
              <br>
              <span>{{ formatFacName() }}</span>
            </div>
            <div class="mt-4">
              <span>{{ $t('common.roomType') }}</span>
              <br>
              <span>{{ formatRoomType() }}</span>
            </div>
            <div class="mt-4">
              <span>{{ $t('common.date') }}</span>
              <div class="d-flex align-center mt-0">
                <div class="d-flex">
                  <v-menu
                    disabled
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <div class="custom-btn-picker">
                        <v-btn
                          style="opacity: 0.3"
                          disabled
                          outlined
                          v-on="on"
                          small
                          class="bg-white--btn"
                          >{{ availableFromDate }}</v-btn
                        >
                      </div>
                    </template>
                    <v-date-picker
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      @input="menuShowDate = false; menuShowTodate = true"
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                   <span class="pl-1 pr-1">〜</span>
                   <v-menu
                    disabled
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <div class="custom-btn-picker">
                        <v-btn
                          style="opacity: 0.3"
                          disabled
                          outlined
                          v-on="on"
                          small
                          class="bg-white--btn mr-5"
                          >{{ availableToDate }}</v-btn
                        >
                      </div>
                    </template>
                    <v-date-picker
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      @input="menuShowDate = false; menuShowTodate = true"
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <span>{{ $t('invitationsAndCoupons.reservedPath') }}</span>
              <br>
              <v-checkbox
                disabled
                v-model="bookCallCenter"
                hide-details
                :label="$t('invitationsAndCoupons.callCenter')"
              ></v-checkbox>
              <v-checkbox
                disabled
                v-model="bookSalesRep"
                hide-details
                :label="$t('invitationsAndCoupons.sales')"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col :cols="4" class="list-item">
            <div>
              <span>{{ $t('invitationsAndCoupons.dayOfWeek') }}</span>
              <div class="d-flex justify-space-between">
                <v-checkbox
                  disabled
                  v-model="availableMonday"
                  hide-details
                  :label="$t('weekdays.monday')"
                />
                <v-checkbox
                  disabled
                  v-model="availableTuesday"
                  hide-details
                  :label="$t('weekdays.tuesday')"
                />
                <v-checkbox
                  disabled
                  v-model="availableWednesday"
                  hide-details
                  :label="$t('weekdays.wednesday')"
                />
                <v-checkbox
                  disabled
                  v-model="availableThursday"
                  hide-details
                  :label="$t('weekdays.thursday')"
                />
              </div>
              <div class="d-flex justify-space-between">
                <v-checkbox
                  disabled
                  v-model="availableFriday"
                  hide-details
                  :label="$t('weekdays.friday')"
                />
                <v-checkbox
                  disabled
                  v-model="availableSaturday"
                  hide-details
                  :label="$t('weekdays.saturday')"
                />
                <v-checkbox
                  disabled
                  v-model="availableSunday"
                  hide-details
                  :label="$t('weekdays.sunday')"
                />
                <v-checkbox
                  disabled
                  v-model="availableHoliday"
                  hide-details
                  :label="$t('weekdays.holidays')"
                />
              </div>
            </div>
            <div class="mt-4">
              <span>{{ $t('invitationsAndCoupons.exclusionDay') }}</span>
              <br>
              <span>{{ formatString(exceptDates) }}</span>
            </div>
            <div class="mt-4">
              <span>{{ $t('invitationsAndCoupons.userOrNot') }}</span>
              <v-checkbox
                disabled
                v-model="nightwear"
                hide-details
                :label="$t('invitationsAndCoupons.nightwear')"
              ></v-checkbox>
              <v-checkbox
                disabled
                v-model="toothbrush"
                hide-details
                :label="$t('invitationsAndCoupons.toothbrush')"
              ></v-checkbox>
              <v-checkbox
                disabled
                v-model="razor"
                hide-details
                :label="$t('invitationsAndCoupons.shaving')"
              ></v-checkbox>
              <v-checkbox
                disabled
                v-model="rentalItem"
                hide-details
                :label="$t('invitationsAndCoupons.itemAvailableRent')"
              ></v-checkbox>
            </div>
            <div class="mt-4">
              <span>{{ $t('invitationsAndCoupons.amountUsed') }}</span>
              <v-checkbox
                disabled
                v-model="free"
                hide-details
                :label="$t('invitationsAndCoupons.free')"
              ></v-checkbox>
              <div class="d-flex align-end">
                <v-checkbox
                  disabled
                  v-model="fixedFree"
                  hide-details
                  :label="$t('invitationsAndCoupons.fixedFree')"
                ></v-checkbox>
                <span class="ml-2 font-weight-bold" style="font-size: 8px">{{ $t('invitationsAndCoupons.taxInclude') }}</span>
                <span class="ml-5">{{accommodationPrice.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })}}</span>
              </div>
            </div>
          </v-col>
          <v-col :cols="4" class="pl-8 list-item">
            <div>
              <span>{{ $t('invitationsAndCoupons.cancelPolicy') }}</span>
              <br>
              <span>宿泊日から{{cancelDeadline}}日前以降</span>
            </div>
            <div class="mt-4">
              <span>{{ $t('invitationsAndCoupons.reservationStartDate') }}</span>
              <br>
              <span>{{ bookingFromDate }}</span>
            </div>
            <div class="mt-4">
              <span>{{ $t('invitationsAndCoupons.reservationPeriod') }}</span>
              <br>
              <span>宿泊日から{{bookingFromMonths}}か月{{bookingFromDays}}日前</span>
            </div>
            <div class="mt-4">
              <span>{{ $t('invitationsAndCoupons.amountRightReserved') }}</span>
              <br>
              <span>{{assetValue.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })}}</span>
            </div>
          </v-col>
        </v-row>
        <v-form ref="form">
          <v-row class="px-10 list-item mt-10">
              <div style="width: 100%">
              <span>{{ $t('common.remark') }}</span>
              <v-text-field
                v-model="notes"
                :rules="rules.notes"
                dense
              />
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-5">
        <v-row class="mt-1">
          <v-col :cols="6" class="d-flex justify-start">
          </v-col>
          <v-col :cols="6" class="d-flex justify-end">
            <v-btn
              color="var(--bt__red)"
              class="mr-3 d-flex align-center btn_status white--text"
              @click="closeDialog"
            >
              <v-icon class="white--text"> mdi-close </v-icon>
              {{ $t('common.close') }}
            </v-btn>
            <v-btn
              class="white--text"
              color="var(--bt__blue)"
              @click="actionUpdateTicket()"
            >
              {{ $t('common.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { BOOKING_TICKET_TYPE } from '@/api/graphql/invitation-ticket/invitation-ticket'
import { mapMutations, mapActions } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'ticketDetail',
  props: {
    showDialog: Boolean,
    showItem: Boolean,
    id: String,
    invitation: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      fixedFree: false,
      free: false,
      name: '',
      issuedCount: 0,
      facilityNames: [],
      roomTypeIds: [],
      availableFromDate: '',
      availableToDate: '',
      bookCallCenter: false,
      bookSalesRep: false,
      availableSunday: false,
      availableMonday: false,
      availableTuesday: false,
      availableWednesday: false,
      availableThursday: false,
      availableFriday: false,
      availableSaturday: false,
      availableHoliday: false,
      exceptDates: [],
      nightwear: false,
      toothbrush: false,
      razor: false,
      rentalItem: false,
      accommodationPrice: 0,
      cancelDeadline: 0,
      bookingFromDate: '',
      bookingFromDays: 0,
      bookingFromMonths: 0,
      assetValue: 0,
      notes: '',
      roomTypeList: [],
      rules: {
        notes: [(v) => !!v || this.$t('rules.isRequired')]
      },

      menuDate: [],
      listDate: [
        { id: 1, date: new Date().toISOString().substr(0, 10) },
        { id: 2, date: new Date().toISOString().substr(0, 10) }
      ]
    }
  },

  mounted () {
    this.getBookingTicketType()
  },

  methods: {
    ...mapMutations({ setInvitationTicket: 'setInvitationTicket' }),
    ...mapActions({ actionUpdate: 'updateBookingTicketType' }),

    formatFacName () {
      if (this.facilityNames !== []) {
        const arrayId = []
        const arrName = []
        for (let i = 0; i < this.facilityNames.length; i++) {
          if (!arrayId.includes(this.facilityNames[i].id)) {
            arrayId.push(this.facilityNames[i].id)
            arrName.push(this.facilityNames[i].name)
          }
        }
        return arrName.join(' ,')
      }
    },

    formatRoomType () {
      if (this.roomTypeList !== []) {
        const arrayId = []
        const arrName = []
        for (let i = 0; i < this.roomTypeList.length; i++) {
          if (!arrayId.includes(this.roomTypeList[i].id)) {
            arrayId.push(this.roomTypeList[i].id)
            arrName.push(this.roomTypeList[i].name)
          }
        }
        return arrName.join(' ,')
      }
    },

    actionUpdateTicket () {
      if (this.$refs.form.validate()) {
        const invitationTicket = {
          idTicket: this.id,
          notes: this.notes
        }
        this.setInvitationTicket({ invitationTicket: invitationTicket })
        this.actionUpdate()
        this.closeDialog()
        this.$emit('reloadApi')
      }
    },

    async getBookingTicketType () {
      await this.$apollo
        .query({
          query: gql`${BOOKING_TICKET_TYPE}`,
          variables: {
            id: this.id
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.name = data.data.bookingTicketType.name
          this.issuedCount = data.data.bookingTicketType.computed.issuedCount
          this.availableFromDate = data.data.bookingTicketType.availableFromDate
          this.availableToDate = data.data.bookingTicketType.availableToDate
          this.bookCallCenter = data.data.bookingTicketType.bookCallCenter
          this.bookSalesRep = data.data.bookingTicketType.bookSalesRep
          this.availableSunday = data.data.bookingTicketType.availableSunday
          this.availableMonday = data.data.bookingTicketType.availableMonday
          this.availableTuesday = data.data.bookingTicketType.availableTuesday
          this.availableWednesday = data.data.bookingTicketType.availableWednesday
          this.availableThursday = data.data.bookingTicketType.availableThursday
          this.availableFriday = data.data.bookingTicketType.availableFriday
          this.availableSaturday = data.data.bookingTicketType.availableSaturday
          this.availableHoliday = data.data.bookingTicketType.availableHoliday
          this.nightwear = data.data.bookingTicketType.nightwear
          this.toothbrush = data.data.bookingTicketType.toothbrush
          this.razor = data.data.bookingTicketType.razor
          this.rentalItem = data.data.bookingTicketType.rentalItem
          this.accommodationPrice = data.data.bookingTicketType.accommodationPrice
          this.cancelDeadline = data.data.bookingTicketType.cancelDeadline
          this.bookingFromDate = data.data.bookingTicketType.bookingFromDate
          this.bookingFromDays = data.data.bookingTicketType.bookingFromDays
          this.bookingFromMonths = data.data.bookingTicketType.bookingFromMonths
          this.assetValue = data.data.bookingTicketType.assetValue
          this.notes = data.data.bookingTicketType.notes
          this.facilityNames = []
          this.roomTypeList = []
          this.exceptDates = []
          for (let i = 0; i < data.data.bookingTicketType.roomTypes.length; i++) {
            this.facilityNames.push(data.data.bookingTicketType.roomTypes[i].roomType.facility)
            this.roomTypeList.push(data.data.bookingTicketType.roomTypes[i].roomType)
          }
          this.listExceptdates = data.data.bookingTicketType.exceptDates
          for (let i = 0; i < data.data.bookingTicketType.exceptDates.length; i++) {
            this.exceptDates.push(data.data.bookingTicketType.exceptDates[i].exceptDate)
          }
          if (data.data.bookingTicketType.accommodationPrice > 0) {
            this.free = false
            this.fixedFree = true
          } else {
            this.free = true
            this.fixedFree = false
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    closeDialog () {
      this.$emit('close')
      if (!this.$refs.form.validate()) {
        this.$refs.form.resetValidation()
      }
      this.getBookingTicketType()
    },
    formatString (arr) {
      var str = ''
      for (var index = 0; index < arr.length; index++) {
        str += str === '' ? (arr[index]) : (', ' + arr[index])
      }
      return str
    }
  }
}
</script>

<style scoped lang="scss">
.list-item::v-deep {
  div {
    font-size: 14px;
    span {
      &:first-child{
        opacity: 1;
        color: #212121;
        font-size: 10px !important;
      }
      &:nth-child(3) {
        color: #000000;
        opacity: 0.3;
      }
    }
    .v-label {
      font-size: 14px;
    }
    .v-input--checkbox {
      padding: 0 0 !important;
      margin: 0 0;
    }
  }
}
.size-text-notice {
  font-size: 10px;
}
.title-dialog {
  font-size: 20px;
  color: #000000;
  font-weight: bold !important;
}
.id-item {
  font-size: 12px;
  color: #aaaaaa;

}
</style>
