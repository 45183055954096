<template>
  <div>
    <div class="header-main">
      <h1 class="my-4 page-title-main">
        {{ $t("routeTitles.invitationsAndCoupons") }}
      </h1>
    </div>
    <template>
      <div class="px-main">
        <v-card class="pa-5 mt-5 mb-5">
          <v-row>
            <v-col :cols="6" class="flex-header pb-3">
              <v-text-field
                v-model="ticketNo"
                @change="searchItem"
                append-icon="mdi-magnify"
                placeholder="招待券・利用券No.検索"
                hide-details
                class="t-search ml-5 text--small"
              ></v-text-field>
              <span class="ml-5">{{ $t("common.display") }}</span>
              <v-select
                :items="listStatus"
                @change="changeStatus()"
                v-model="status"
                dense
                small
                color="var(--text__gray)"
                outlined
                hide-details
                class="v-select-custom ml-1"
              ></v-select>
            </v-col>
            <v-col :cols="6" class="d-flex justify-end pr-7">
              <v-btn
                color="var(--text__primary)"
                class="text--white"
                @click="isShowCreateNew = true"
                :disabled="!checkPerUser"
              >
                {{ $t("buttons.createNewInvitation") }}
              </v-btn>
            </v-col>
          </v-row>
            <!-- :options.sync="options" -->
          <v-data-table
            :headers="header"
            :items="bookingTicketTypeList.items"
            :search="searchContentValue"
            :server-items-length="total"
            :items-per-page="30"
            class="pa-5 table-custom"
            :footer-props="{
              itemsPerPageText:
                numLength +
                $t('common.cases') +
                itemStart +
                '~' +
                itemStop +
                $t('common.showing'),
              itemsPerPageOptions: [30, 50, 100, 200, 500]
            }"
          @pagination="getNumOfPage"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ item.createdAt | isoDate }}</span>
            </template>

            <template v-slot:[`item.bookingTickets`]="{ item }">
              <span>{{ item.bookingTickets.length }}</span>
            </template>

            <template v-slot:[`item.roomTypes`]="{ item, on, attrs }">
              <div class="text-truncate" style="max-width: 100px">
                <span
                  v-bind="attrs"
                  v-on="on"
                  v-for="(items, index) in item.roomTypes"
                  :key="index"
                >
                  {{ items.roomType.facility.name }},
                </span>
              </div>
            </template>

            <template v-slot:[`item.issuedCount`]="{ item }">
              <span>{{ item.computed.issuedCount }}</span>
            </template>

            <template v-slot:[`item.sheetsUsedNum`]="{ item }">
              <span>{{ item.computed.usedCount }}</span>
            </template>

            <template v-slot:[`item.available`]="{ item }">
              <span>{{ item.computed.availableCount }}</span>
            </template>

            <template v-slot:[`item.cancelDeadline`]="{ item }">
              <span>宿泊日{{ item.cancelDeadline }}日前</span>
            </template>

            <template v-slot:[`item.bookingFromDays`]="{ item }">
              <span>宿泊日から{{ item.bookingFromDays }}日前</span>
            </template>
            <template v-slot:[`item.bookingFromMonths`]="{ item }">
              <span>宿泊日から{{ item.bookingFromMonths }}か月前</span>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-btn :disabled="!checkPerUser" @click="getIdItem(item.id)" icon>
                <v-icon x-large> mdi-pencil-circle</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </template>
    <CreateInvitationAndCoupon
      v-if="isShowCreateNew"
      :listRoomType="listRoomType"
      :showDialog="isShowCreateNew"
      @close="closeCreateInvitionDialog()"
    />
    <InvitationsAndCouponsDetailDialog
      :key="idx"
      v-if="visible"
      :listRoomType="listRoomType"
      :visible="visible"
      :id="id"
      @close="closeDetailDialog()"
    />
  </div>
</template>
<script>
import CreateInvitationAndCoupon from './createInvitationAndCoupon'
import { BOOKING_TICKET_TYPE_LIST } from '@/api/graphql/invitation-ticket/invitation-ticket'
import InvitationsAndCouponsDetailDialog from './invitationAndCouponsDetailDialog.vue'
import { mapGetters, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  name: 'invitationsAndCouponsList',
  data () {
    return {
      options: {
      },
      timeoutSearch: null,
      total: 0,
      skip: 0,
      take: 30,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      idx: 0,
      listRoomType: [],
      visible: false,
      id: 'WY-2961',
      status: '期限内のみ',
      listStatus: ['全て', '期限内のみ', '期限外のみ'],
      isAvailable: true,
      ticketNo: '',
      bookingTicketTypeList: [],
      isShowCreateNew: false,
      searchContentValue: null,
      invitationAndCouponList: [],
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      header: [
        {
          text: '',
          value: 'edit',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.dateOfIssue'),
          value: 'createdAt',
          // sortable: true
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.deadlineForUse'),
          value: 'availableToDate',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.invitationTicketNo'),
          value: 'id',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.ticketIssueNum'),
          value: 'issuedCount',
          sortable: false
        },
        {
          text: this.$t('common.title'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('common.facility'),
          value: 'roomTypes',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.expirationDate'),
          value: 'cancelDeadline',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.reservationStartDate'),
          value: 'bookingFromDate',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.reservationPeriod') + '（月）',
          value: 'bookingFromMonths',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.reservationPeriod') + '（日）',
          value: 'bookingFromDays',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.sheetsUsedNum'),
          value: 'sheetsUsedNum',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.available'),
          value: 'available',
          sortable: false
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['getStatusCreateSuccess'])
  },

  watch: {
    getStatusCreateSuccess () {
      this.getBookingTicketTypeList(null)
    }

    // options: {
    //   handler () {
    //     this.fetchBookingListTmp()
    //   },
    //   deep: true
    // }
  },

  mounted () {
    this.getBookingTicketTypeList(null)
  },

  methods: {
    ...mapMutations({ setInvitationTicket: 'setInvitationTicket' }),

    // fetchBookingListTmp () {
    //   const arrSortBook = ['createdAt']
    //   const arrOrder = []
    //   if (this.options.sortBy.length > 0) {
    //     this.options.sortBy.map((orderItem, ind) => {
    //       this.options.sortDesc.map((directionItem, index) => {
    //         if (ind === index) {
    //           let orderItemTmp = null
    //           if (arrSortBook.includes(orderItem)) {
    //             orderItemTmp = {
    //               fieldName: `ticketType.${orderItem}`,
    //               direction: directionItem === true ? 'DESC' : 'ASC'
    //             }
    //           }
    //           arrOrder.push(orderItemTmp)
    //         }
    //       })
    //     })
    //   }
    //   this.getBookingTicketTypeList(arrOrder)
    // },

    searchItem () {
      this.getBookingTicketTypeList(null)
    },

    getIdItem (id) {
      this.id = id
      this.visible = true
      this.idx = this.idx + 1
      this.setInvitationTicket(id)
    },

    changeStatus () {
      if (this.status === '全て') {
        this.isAvailable = null
      } else if (this.status === '期限内のみ') {
        this.isAvailable = true
      } else {
        this.isAvailable = false
      }
      this.getBookingTicketTypeList(null)
    },

    async getBookingTicketTypeList (orderBy) {
      await this.$apollo
        .query({
          query: gql`${BOOKING_TICKET_TYPE_LIST}`,
          variables: {
            ticketNo: this.ticketNo,
            isAvailable: this.isAvailable,
            skip: this.skip,
            take: this.take,
            orderBy: orderBy
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.bookingTicketTypeList = data.data.bookingTicketTypeList
          this.total = this.bookingTicketTypeList.total
          this.getListRoomType()
          const invitationTicket = {
            listRoomType: this.listRoomType
          }
          this.setInvitationTicket({ invitationTicket: invitationTicket })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getListRoomType () {
      for (let i = 0; i < this.bookingTicketTypeList.items.length; i++) {
        for (
          let j = 0;
          j < this.bookingTicketTypeList.items[i].roomTypes.length;
          j++
        ) {
          if (
            this.listRoomType.indexOf(
              this.bookingTicketTypeList.items[i].roomTypes[j].roomType.id
            ) === -1
          ) {
            this.listRoomType.push(
              this.bookingTicketTypeList.items[i].roomTypes[j].roomType.id
            )
          }
        }
      }
    },

    closeCreateInvitionDialog () {
      this.isShowCreateNew = false
    },

    closeDetailDialog () {
      this.getBookingTicketTypeList(null)
      this.visible = false
    },

    getNumOfPage (pagination) {
      this.numLength = pagination.itemsLength
      this.itemStart = pagination.pageStart + 1
      this.itemStop = pagination.pageStop
      this.take = pagination.itemsPerPage
      this.skip = (pagination.page - 1) * pagination.itemsPerPage
      this.getBookingTicketTypeList(null)
    }
  },
  components: {
    CreateInvitationAndCoupon,
    InvitationsAndCouponsDetailDialog
  }
}
</script>

<style scoped lang="scss">
.header-main {
  display: flex;
  justify-content: space-between;
}

.px-main {
  min-height: 80vh;
}
.p-relative {
  position: relative;
}
::v-deep {
  &.search-results {
    position: absolute;
    top: calc(100% - 10px);
    right: 173px;
    width: 400px;
    max-height: 552px;
    overflow-x: auto;
    z-index: 1;
    &__item:not(:last-child) {
      border-bottom: solid 1px var(--border__white);
    }
  }
  .t-search {
    margin-top: 0;
    padding: 0;
    max-width: 240px;
  }
  .v-btn__content .v-icon {
    color: #757575;
  }
  .v-data-footer {
    .v-data-footer__select {
      color: #000000;
    }
    .v-data-footer__pagination {
      display: none;
    }
    .v-input__append-inner {
      .v-icon__svg {
        color: #000000
      }
    }
  }
}
</style>
