<template>
  <div>
    <v-dialog v-model="visible" @click:outside="closeDialog" width="1600">
      <v-card-text class="d-flex justify-space-between align-center header">
        <div class="d-flex">
          <h1 class="ml-7 my-4 page-title-main">
            {{ $t("routeTitles.invitationsAndCoupons") }}
          </h1>
        </div>
        <div>
          <v-btn
            color="#1873D9"
            class="white--text ml-2 pa-5"
            @click="isShowCreateNew = true"
          >
            {{ $t("buttons.coppyAndCreate") }}
          </v-btn>
          <v-btn color="#1873D9" class="white--text ml-2 pa-5" @click="showTicketDetail()">
            {{ $t("buttons.detailTicket") }}
          </v-btn>
          <v-btn
            color="#C92D2D"
            class="white--text ml-2 pa-5"
            @click="clearSelect()"
          >
            <v-icon class="mr-1"> mdi-close </v-icon>
            {{ $t("buttons.close") }}
          </v-btn>
          <v-btn
            color="#1873D9"
            class="white--text ml-2 pa-5"
            @click="actionUpdate()"
          >
            {{ $t("buttons.save") }}
          </v-btn>
        </div>
      </v-card-text>
      <template>
        <div class="px-main">
          <v-row>
            <v-col :cols="5" class="d-flex justify-center">
              <div class="table-item">
                <div>
                  <span>{{ $t("invitationsAndCoupons.ticketNo") }} ：</span>
                  <span class="pl-2">{{ id }}</span>
                </div>
                <div>
                  <span
                    >{{ $t("invitationsAndCoupons.numSheetIssued") }}：</span
                  >
                  <template v-if="listBookingTicketType.computed">
                    <span class="pl-2">{{
                    listBookingTicketType.computed.issuedCount
                  }}</span>
                  </template>
                </div>
                <div>
                  <span>{{ $t("common.title") }}：</span>
                  <span class="pl-2">{{ listBookingTicketType.name }}</span>
                </div>
                <div>
                  <span>{{ $t("common.facility") }}：</span>

                  <div class="pl-2 d-inline">
                    {{getFacilityName(listBookingTicketType.roomTypes)}}
                  </div>
                </div>
                <div>
                  <span>{{ $t("common.roomType") }}：</span>
                  <div
                    class="pl-2 d-inline"
                    v-for="(items, index) in listBookingTicketType.roomTypes"
                    :key="index"
                  >
                    {{ items.roomType.name }},
                  </div>
                </div>
                <div>
                  <span>{{ $t("common.date") }}：</span>
                  <span class="pl-2"
                    >{{ listBookingTicketType.availableFromDate | jaShortDateSlash}} ~
                    {{ listBookingTicketType.availableToDate | jaShortDateSlash }}</span
                  >
                </div>
                <div>
                  <span>{{ $t("invitationsAndCoupons.dayOfWeek") }}：</span>
                  <span class="pl-2">{{
                    formatStringFromCheckbox(arrCheckDay, dayOfWeek)
                  }}</span>
                </div>
                <div>
                  <span>{{ $t("invitationsAndCoupons.exclusionDay") }}：</span>
                  <span class="pl-2">
                    {{ (listBookingTicketType.exceptDates && listBookingTicketType.exceptDates.length > 0) ? listBookingTicketType.exceptDates.map(item => (
                      item.exceptDate
                    )).join(', ').split('-').join('/') : 'なし' }}
                  </span>
                </div>
                <div>
                  <span>{{ $t("invitationsAndCoupons.userOrNot") }}：</span>
                  <span class="pl-2">{{
                    formatStringCheckData(arrCheckUseOrNot, useOrNot)
                  }}</span>
                </div>
              </div>
            </v-col>
            <v-col :cols="3" class="d-flex justify-center">
              <div class="table-item">
                <div>
                  <span>{{ $t("invitationsAndCoupons.amountUsed") }}：</span>
                  <tvos-jpy :value="listBookingTicketType.accommodationPrice" class="pl-2" />
                </div>
                <div>
                  <span>{{ $t("invitationsAndCoupons.reservedPath") }}：</span>
                  <span class="pl-2">{{
                    formatStringFromCheckbox(arrbookCallAndSale, reservedPath)
                  }}</span>
                </div>
                <div>
                  <span
                    >{{ $t("invitationsAndCoupons.expirationDate") }}：</span
                  >
                  <span class="pl-2"
                    >宿泊日{{ listBookingTicketType.cancelDeadline }}日前</span
                  >
                </div>
                <div>
                  <span
                    >{{
                      $t("invitationsAndCoupons.reservationStartDate")
                    }}：</span
                  >
                  <span class="pl-2">{{
                    listBookingTicketType.bookingFromDate | jaShortDateSlash
                  }}</span>
                </div>
                <div>
                  <span
                    >{{ $t("invitationsAndCoupons.reservationPeriod") }}：</span
                  >
                  <span class="pl-2"
                    >宿泊日から{{
                      listBookingTicketType.bookingFromMonths
                    }}か月{{
                      listBookingTicketType.bookingFromDays
                    }}日前</span
                  >
                </div>
                <div>
                  <span
                    >{{
                      $t("invitationsAndCoupons.fixedAmountRightSetting")
                    }}：</span
                  >
                  <tvos-jpy :value="listBookingTicketType.assetValue" class="pl-2" />
                </div>
                <div>
                  <span>{{ $t("invitationsAndCoupons.numSheetUser") }}：</span>
                  <template v-if="listBookingTicketType.computed">
                    <span class="pl-2">{{
                    listBookingTicketType.computed.usedCount
                  }}</span>
                  </template>
                </div>
                <div>
                  <span
                    >{{ $t("invitationsAndCoupons.numSheetAvailable") }}：</span
                  >
                  <template v-if="listBookingTicketType.computed">
                    <span class="pl-2">{{
                      listBookingTicketType.computed.availableCount
                    }}</span>
                  </template>
                </div>
                <div>
                  <span>{{ $t("common.remark") }}：</span>
                  <span style="max-width: 300px; word-break: break-all;"
                    class="pl-2"
                    v-if="listBookingTicketType.notes != null"
                    >{{ listBookingTicketType.notes }}</span
                  >
                  <span class="pl-2" v-else>-</span>
                </div>
              </div>
            </v-col>
            <v-col :cols="3" class="d-flex justify-center">
              <div>
                <div class="table-item">
                  <div>
                    <span>{{ $t("invitationsAndCoupons.dateOfIssue") }}：</span>
                    <template v-if="listBookingTicketType.createdAt">
                      <span class="pl-2">{{
                        listBookingTicketType.createdAt | jaShortDateSlash
                    }}</span>
                    </template>
                  </div>
                  <div>
                    <span
                      >{{ $t("invitationsAndCoupons.deadlineForUse") }}：</span
                    >
                    <template v-if="listBookingTicketType.availableToDate">
                      <span class="pl-2">
                        {{ listBookingTicketType.availableToDate | jaShortDateSlash }}
                      </span>
                    </template>
                  </div>
                  <div>
                    <span
                      >{{ $t("invitationsAndCoupons.lastUpdatedBy") }}：</span
                    >
                    <template v-if="listBookingTicketType.createdBy">
                      <span
                      v-if="listBookingTicketType.createdBy !== null"
                      class="pl-2"
                      >{{ listBookingTicketType.createdBy.name }}</span
                    >
                    <span v-else class="pl-2">-</span>
                    </template>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
            <v-card class="mt-10 pa-5">
            <v-row class="d-flex justify-end">
              <v-btn
                color="var(--text__primary)"
                class="text--small"
                outlined
                @click="downloadFilePdf()"
                >{{ $t("invitationsAndCoupons.exportCsv") }}</v-btn
              >
            </v-row>
            <div class="mt-6">
              <Table
                :attr="{
                  'item-key': 'id',
                }"
                ref="table"
                :items="items"
                :headers="header"
                :itemsPerPage="10"
                :total="totalCount"
                :itemsPerPageOptions="[10, 30, 50, 100, 200]"
                :funReset="loadTickets"
                :filter="{}"
                :multi-sort="true"
                :sortField="[]"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  <span v-if="item.booking != null && item.disabled == false">{{
                    item.booking.createdAt | formatDate
                  }}</span>
                </template>
                <template v-slot:item.facilityName="{item}">
                  <template v-if="item.booking">
                    {{  getFacilityNameForTicket(item) }}
                  </template>
                </template>
                <template v-slot:item.checkInDate="{item}">
                  <template v-if="item.booking">
                    {{  item.booking.checkInDate | formatDate }}
                  </template>
                </template>
                <template v-slot:[`item.representativeName`]="{ item }">
                  <span v-if="item.booking !== null && item.booking.representative">
                    {{
                      item.booking.representative.name
                    }}
                  </span>
                </template>
                <template v-slot:item.bookingStatus="{item}">
                  <template v-if="item.booking && item.booking.computed">
                    {{  item.booking.computed.status }}
                  </template>
                </template>
                <template v-slot:[`item.disabled`]="{ item }">
                    <v-select
                      v-model="item.disabled"
                      :items="itemDisabled"
                      outlined
                      hide-details
                      dense
                      class="text-size-normal"
                    ></v-select>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      v-if="item.bookingId"
                      outlined
                      color="var(--bt__blue)"
                      @click="$goToRoute.booking.open(item.bookingId)"
                    >
                      {{ $t("invitationsAndCoupons.btnReservationDetails") }}
                    </v-btn>
                    <v-btn v-else-if="item.isAvailable" color="var(--bt__blue)" class="white--text" @click="actionNewTab(item)">{{
                      $t("invitationsAndCoupons.btnNewReservation")
                    }}</v-btn>
                </template>
              </Table>
            </div>
          </v-card>
        </div>
      </template>
      <CreateInvitationAndCoupon
        v-if="isShowCreateNew"
        :listBookingTicketType="listBookingTicketType"
        :listRoomType="listRoomType"
        :showDialog="isShowCreateNew"
        :invitation="invitationData"
        @close="isShowCreateNew = false"
      />
      <TicketDetail
        :id="id"
        :showItem="showItem"
        :show-dialog="isShowTicketDetail"
        :invitation="invitationData"
        @reloadApi="reloadApi"
        @close="isShowTicketDetail = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import CreateInvitationAndCoupon from './createInvitationAndCoupon'
import TicketDetail from '@/views/dashboard/invitationsAndCoupons/ticketDetail'
import { BOOKING_TICKET_TYPE } from '@/api/graphql/invitation-ticket/invitation-ticket'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { handlError, getCopyErrorTextView } from '@/constants/functions'
import gql from 'graphql-tag'
import { downloadFile } from '@/utils/downloadFile'
import Table from '@/components/Table/index.vue'

export default {
  name: 'InvitationsAndCouponsDetailDialog',
  props: {
    visible: Boolean,
    id: String,
    listRoomType: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showItem: false,
      items: [],
      totalCount: 0,
      idItem: null,
      disabledItem: false,
      disabled: '有効',
      listBookingTicketType: [],
      arrCheckDay: [],
      arrbookCallAndSale: [],
      isShowCreateNew: false,
      isShowTicketDetail: false,
      itemDisabled: [{
        text: '無効',
        value: true
      }, {
        text: '有効',
        value: false
      }],
      reservedPath: ['コールセンター', '営業'],
      useOrNot: ['歯ブラシ', '髭剃り', 'ナイトウェア', '貸出品利用可能'],
      arrCheckUseOrNot: [],
      dayOfWeek: ['日', '月', '火', '水', '木', '金', '土', '祝'],
      userOrNot: ['ナイトウェア', '歯ブラシ', '髭剃り', '貸出品利用可能'],
      amountUsed: ['無料', '固定料金'],
      invitationData: {
        title: 'Room Charge 50%OFFクーポン',
        facility: '京都',
        roomType: ['禁煙スタンダード'],
        listDate: [
          { id: 1, date: '2020/11/03' },
          { id: 2, date: '2021/12/31' }
        ],
        reservedPath: [1, 1],
        dayOfWeek: [1, 1, 1, 1, 0, 0, 0, 0],
        exclusionDay: '0000-00-00',
        userOrNot: [1, 0, 0, 0],
        amountUsed: [1, 0],
        reservationStartDate: '2021/02/01',
        reservationPeriod: '宿泊日から30日前'
      },
      invitationsAndCouponsList: [],
      header: [
        { text: this.$t('invitationsAndCoupons.ticketNo'), value: 'id', fieldName: 'ticket.id' },
        {
          text: this.$t('invitationsAndCoupons.authenticationNum'),
          value: 'secret',
          sortable: false
        },
        {
          text: this.$t('invitationsAndCoupons.applicationDate'),
          value: 'createdAt',
          fieldName: 'booking.createdAt'
        },
        {
          text: this.$t('invitationsAndCoupons.representativeName'),
          value: 'representativeName',
          fieldName: 'rep.name'
        },
        {
          text: '宿泊施設',
          value: 'facilityName'
        },
        {
          text: '宿泊開始日',
          value: 'checkInDate'
        },
        {
          text: 'ステータス',
          value: 'bookingStatus'
        },
        {
          text: this.$t('invitationsAndCoupons.enabledOrDisable'),
          value: 'disabled',
          width: '80px',
          height: '40px',
          fieldName: 'ticket.disabled'
        },
        { text: '', value: 'action', align: 'end', sortable: false }
      ]
    }
  },

  mounted () {
    this.getBookingTicketType()
  },

  watch: {
    id () {
      this.getBookingTicketType()
    }
  },

  methods: {
    getCopyErrorTextView,
    ...mapMutations({
      setCopyErrorText: 'setCopyErrorText',
      setInvitationTicket: 'setInvitationTicket',
      setLoadingOverlayHide: 'setLoadingOverlayHide',
      setLoadingOverlayShow: 'setLoadingOverlayShow'
    }),
    ...mapActions({ actionDisableBookingTicket: 'disableBookingTicket' }),
    downloadFile,
    getFacilityNameForTicket (item) {
      return item?.booking?.room?.roomType?.facility?.name
    },
    async downloadFilePdf () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: String!){
            bookingTicketCsv(id:$id){
              files {
                fileName
                downloadUrl
              }
            }
          }`,
          variables: {
            id: this.id
          }
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          if (data.data.bookingTicketCsv.files.length > 0) {
            for (let i = 0; i < data.data.bookingTicketCsv.files.length; i++) {
              this.downloadFile(data.data.bookingTicketCsv.files[i].downloadUrl, data.data.bookingTicketCsv.files[i].fileName)
            }
          }
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },

    reloadApi () {
      setTimeout(this.getBookingTicketType, 500)
    },

    closeDialog () {
      this.$emit('close')
    },

    clearSelect () {
      this.closeDialog()
    },

    getSelect (event) {
      this.idItem = event.id
      this.disabledItem = event.disabled
    },

    async actionUpdate () {
      for (const item of this.items) {
        if (item.disabled !== item.originalDisabled) {
          await this.actionDisableBookingTicket({
            ticketId: item.id,
            disabled: item.disabled
          })
        }
      }
    },

    getFacilityName (roomTypeList) {
      if (roomTypeList) {
        const arrayId = []
        const arrName = []
        for (let i = 0; i < roomTypeList.length; i++) {
          if (!arrayId.includes(roomTypeList[i].roomType.facility.id)) {
            arrayId.push(roomTypeList[i].roomType.facility.id)
            arrName.push(roomTypeList[i].roomType.facility.name)
          }
        }
        return arrName.join(' ,')
      }
    },

    async loadTickets (variables) {
      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`query bookingTicketList (
          $id: String!
          $pagination: PaginationInput
          $orderBy: [OrderByInput!]
        ) {
          bookingTicketList (
            bookingTicketTypeId: $id
            pagination: $pagination
            orderBy: $orderBy
          ) {
            total
            items {
              isUsed
              bookingId
              id
              secret
              #email
              booking {
                id
                computed { status }
                bookingGroupId
                bookingTypeId
                checkInDate
                representative {
                  email
                  name
                  tel
                  address1
                }
                createdAt
                room {
                  roomType {
                    facility {
                      name
                    }
                  }
                }
              }
              isAvailable
              disabled
              isUsed
            }
          }
        }`,
        variables: {
          id: this.id,
          pagination: variables.pagination,
          orderBy: variables.orderBy
        }
      })

      this.items = result.data.bookingTicketList.items.map(item => {
        return {
          ...item,
          originalDisabled: item.disabled
        }
      })
      this.totalCount = result.data.bookingTicketList.total
    },

    showTicketDetail () {
      this.isShowTicketDetail = true
      this.showItem = !this.showItem
      const invitationTicket = {
        ticketId: this.id
      }
      this.setInvitationTicket({ invitationTicket: invitationTicket })
    },

    async getBookingTicketType () {
      const variables = {
        id: this.id
      }
      await this.$apollo
        .query({
          query: gql`${BOOKING_TICKET_TYPE}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.listBookingTicketType = data.data.bookingTicketType
          this.arrCheckUseOrNot = []
          this.arrCheckDay = []
          this.arrbookCallAndSale = []
          this.arrCheckDay.push(
            this.listBookingTicketType.availableSunday,
            this.listBookingTicketType.availableMonday,
            this.listBookingTicketType.availableTuesday,
            this.listBookingTicketType.availableWednesday,
            this.listBookingTicketType.availableThursday,
            this.listBookingTicketType.availableFriday,
            this.listBookingTicketType.availableSaturday,
            this.listBookingTicketType.availableHoliday
          )
          this.arrCheckUseOrNot.push(
            this.listBookingTicketType.toothbrush,
            this.listBookingTicketType.razor,
            this.listBookingTicketType.nightwear,
            this.listBookingTicketType.rentalItem
          )
          this.arrbookCallAndSale.push(
            this.listBookingTicketType.bookCallCenter,
            this.listBookingTicketType.bookSalesRep
          )
        })
        .catch((error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(BOOKING_TICKET_TYPE, variables, error.graphQLErrors))
          handlError(this.$store.commit, error.graphQLErrors)
        })
    },

    formatStringFromCheckbox (arrCheckbox, arrValue) {
      var str = ''
      for (var index = 0; index < arrValue.length; index++) {
        if (arrCheckbox[index]) {
          str += str === '' ? arrValue[index] : '/ ' + arrValue[index]
        }
      }
      return str
    },

    formatStringCheckData (arrCheckbox, arrValue) {
      var str = ''
      for (var index = 0; index < arrValue.length; index++) {
        if (arrCheckbox[index]) {
          str += str === '' ? arrValue[index] : '・' + arrValue[index]
        }
      }
      return str
    },

    actionNewTab (item) {
      const routeData = `/invitations-and-coupons-create?ticketNo=${this.id}&ticketId=${item.id}`
      window.open(routeData, '_blank')
    }
  },

  computed: {
    ...mapGetters(['getInvitationTicketId'])
  },

  components: {
    CreateInvitationAndCoupon,
    TicketDetail,
    Table
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-data-table {
    tbody tr td {
      text-align: center;
      font-size: 14px !important;
    }
  }
  .text-size-normal .v-select__selection--comma{
  color: #000000 !important
}
.text-size-normal .v-icon__svg{
  color: #000000 !important
}
}
.table-item {
  color:#000000 !important;
  span:first-child {
    white-space: nowrap;
  }
}
.header {
  background-color: #ffffff;
}

.px-main {
  min-height: 80vh;
}
.color-btn-used {
  color: #757575;
}

</style>
