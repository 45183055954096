var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',_vm._b({staticClass:"elevation-1 table-custom",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.searching,"showSelect":_vm.showSelect,"items-per-page":_vm.options.itemsPerPage,"server-items-length":_vm.total && !_vm.disableServerItemsLength ? _vm.total : -1,"hide-default-footer":(!_vm.hideFooter && _vm.data && _vm.data.length) > 0 ? false : true,"overflowX":"","sort-by":_vm.defaultSortBy,"calculate-widths":"","options":_vm.options,"disable-sort":_vm.disableSort,"multi-sort":_vm.multiSort,"disable-pagination":_vm.disablePagination,"footer-props":{
      itemsPerPageText:
        "" + (_vm.total && !_vm.disableServerItemsLength ? _vm.total : _vm.data.length) +
        _vm.$t('common.cases') +
        _vm.itemStart +
        '~' +
        _vm.itemStop +
        _vm.$t('common.showing'),
      itemsPerPageOptions: _vm.itemsPerPageOptions,
      pageText: '',
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
    var item = ref.item;
return [_vm._t([("item." + (header.value))],[_c('span',{key:header.value,attrs:{"title":_vm.getData(item, header.value)}},[_vm._v(_vm._s(_vm.getData(item, header.value)))])],{"item":item})]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-data-table',Object.assign({}, _vm.attr),false),[_c('template',{slot:"no-data"},[_vm._v(" データはありません ")]),_c('template',{slot:"top"},[_vm._t("top")],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }