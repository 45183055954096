<template>
  <v-dialog v-model="showDialog" width="1200px" persistent>
    <v-form ref="form">
      <v-card>
        <v-card-title class="title-dialog">{{
          $t("invitationsAndCoupons.createNewInvitationTitle")
        }}</v-card-title>
        <v-card-text>
          <v-row class="pa-10 pb-0">
            <v-col :cols="4" class="pr-8 list-item">
              <div>
                <span class="title-item">{{ $t("common.title") }}</span>
                <v-text-field
                  value="number"
                  :rules="rules.nameTicket"
                  v-model="nameTicket"
                  dense
                />
              </div>
              <div>
                <span class="title-item">{{
                  $t("common.numberOfSheets")
                }}</span>
                <tvos-int-input
                  :max="999"
                  :rules="rules.numberOfTickets"
                  v-model="numberOfTickets"
                  dense
                />
              </div>
              <div>
                <span class="title-item">{{ $t("common.facility") }}</span>
               <v-select
                  multiple
                  @change="getSelectFacility($event)"
                  :rules="rules.roomTypeIds"
                  v-model="listFac"
                  item-text="name"
                  item-value="id"
                  :items="facilityList"
                  dense
                />
              </div>
              <div>
                <span class="title-item">{{ $t("common.roomType") }}</span>
                <v-select
                  :disabled="selectFacility"
                  :rules="rules.roomTypeIds"
                  v-model="roomTypeIds"
                  :items="listRoomType"
                  item-value="id"
                  item-text="name"
                  multiple
                  dense
                />
              </div>
            </v-col>
            <v-col :cols="4" class="list-item">
              <div>
                <span class="title-item">日付</span>
                <div class="d-flex custom-picker">
                  <v-icon>mdi-calendar-month</v-icon>
                  <v-menu
                    v-model="menuShowDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <div class="custom-btn-picker">
                        <v-btn
                          outlined
                          :rules="rules.fromDate"
                          v-model="fromDate"
                          v-on="on"
                          small
                          class="bg-white--btn mr-5"
                          >{{ formatDate(fromDate) }}</v-btn
                        >
                      </div>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      @change="changeFromDate($event)"
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      @input="menuShowDate = false; menuShowTodate = true"
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                  <!--  -->
                  <span class="pl-1 pr-1">〜</span>
                  <v-menu
                    v-model="menuShowTodate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <div class="custom-btn-picker">
                        <v-btn
                          outlined
                          v-model="toDate"
                          v-on="on"
                          small
                          class="bg-white--btn mr-5"
                          >{{ formatDate(toDate) }}</v-btn
                        >
                      </div>
                    </template>
                    <v-date-picker
                      :rules="rules.toDate"
                      :min="minToDate"
                      v-model="toDate"
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      @input="menuShowTodate = false"
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                  <!--  -->
                </div>
              </div>

              <div class="mt-3 user-or-not">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.reservedPath")
                }}</span>
                <v-checkbox
                  hide-details
                  v-model="bookCallCenter"
                  :label="$t('invitationsAndCoupons.callCenter')"
                ></v-checkbox>
                <v-checkbox
                  v-model="bookSalesRep"
                  hide-details
                  :label="$t('invitationsAndCoupons.sales')"
                ></v-checkbox>
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.dayOfWeek")
                }}</span>
                <div class="d-flex justify-space-between user-or-not">
                  <v-checkbox
                    v-model="monday"
                    hide-details
                    :label="$t('weekdays.monday')"
                  />
                  <v-checkbox
                    v-model="tuesday"
                    hide-details
                    :label="$t('weekdays.tuesday')"
                  />
                  <v-checkbox
                    v-model="wednesday"
                    hide-details
                    :label="$t('weekdays.wednesday')"
                  />
                  <v-checkbox
                    v-model="thursday"
                    hide-details
                    :label="$t('weekdays.thursday')"
                  />
                </div>
                <div class="d-flex justify-space-between user-or-not">
                  <v-checkbox
                    v-model="friday"
                    hide-details
                    :label="$t('weekdays.friday')"
                  />
                  <v-checkbox
                    v-model="saturday"
                    hide-details
                    :label="$t('weekdays.saturday')"
                  />
                  <v-checkbox
                    v-model="sunday"
                    hide-details
                    :label="$t('weekdays.sunday')"
                  />
                  <v-checkbox
                    v-model="holiday"
                    hide-details
                    :label="$t('weekdays.holidays')"
                  />

                </div>
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.exclusionDay")
                }}</span>
                <v-menu
                  v-model="excludingDayMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex align-center">
                      <v-icon>mdi-calendar-month</v-icon>
                      <v-text-field
                        v-model="excludingDay"
                        v-on="on"
                        clearable
                        readonly
                        class="ml-5 pt-0"
                        >{{ excludingDay }}</v-text-field
                      >
                    </div>
                  </template>
                  <v-date-picker
                    multiple
                    v-model="excludingDay"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    @input="excludingDayMenu = false"
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mt-3 user-or-not">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.userOrNot")
                }}</span>
                <v-checkbox
                  v-model="nightwear"
                  hide-details
                  :label="$t('invitationsAndCoupons.nightwear')"
                ></v-checkbox>
                <v-checkbox
                  v-model="toothbrush"
                  hide-details
                  :label="$t('invitationsAndCoupons.toothbrush')"
                ></v-checkbox>
                <v-checkbox
                  v-model="razor"
                  hide-details
                  :label="$t('invitationsAndCoupons.shaving')"
                ></v-checkbox>
                <v-checkbox
                  v-model="rentalItem"
                  hide-details
                  :label="$t('invitationsAndCoupons.itemAvailableRent')"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col :cols="4" class="pl-8 list-item">
              <div class="user-or-not">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.amountUsed")
                }}</span>
                <v-checkbox
                  @change="getFree($event)"
                  v-model="statusFree"
                  hide-details
                  :label="$t('invitationsAndCoupons.free')"
                ></v-checkbox>
                <div class="d-flex align-end">
                  <v-checkbox
                    style="margin-bottom:15px"
                    v-model="statusNotFree"
                    @change="getNotFree($event)"
                    hide-details
                    :label="$t('invitationsAndCoupons.fixedFree')"
                    class="flex-shrink-0"
                  ></v-checkbox>
                  <span
                    class="ml-2 font-weight-bold flex-shrink-0"
                    style="font-size: 8px; margin-bottom:15px; margin-right: 5px"
                    >{{ $t("invitationsAndCoupons.taxInclude") }}</span
                  >
                  <tvos-int-input
                    :disabled="!statusNotFree"
                    required
                    :class="
                      !statusNotFree ? 'disable': ''
                      "
                      v-model="accommodationPrice"
                      jpy />
                </div>
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.cancelPolicy")
                }}</span>
                <v-select
                  :rules="rules.cancelDeadline"
                  v-model="cancelDeadline"
                  :items="itemCancelDate"
                  dense
                />
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.reservationStartDate")
                }}</span>
                <v-menu
                  v-model="contractStartDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex align-center">
                      <v-icon>mdi-calendar-month</v-icon>
                      <v-text-field
                        :rules="rules.contractStartDate"
                        v-model="contractStartDate"
                        v-on="on"
                        clearable
                        readonly
                        class="ml-5 pt-0"
                        >{{ contractStartDate }}</v-text-field
                      >
                    </div>
                  </template>
                  <v-date-picker
                    v-model="contractStartDate"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    @input="contractStartDateMenu = false"
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mt-5">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.reservationPeriod")
                }}</span>
                <div class="d-flex justify-space-between align-baseline">
                  <div>宿泊</div>
                  <div style="width: 80px">
                    <v-select
                      :rules="rules.bookingFromMonths"
                      v-model="bookingFromMonths"
                      :items="bookingFromMonthsSelect"
                      dense
                      width="80"
                    />
                  </div>
                  <div>か月前</div>
                  <div style="width: 80px">
                    <v-select
                      :rules="rules.bookingFromDays"
                      v-model="bookingFromDays"
                      :items="bookingFromDaysSelect"
                      dense
                    />
                  </div>
                  <div>日前</div>
                </div>
              </div>
              <div class="mt-3">
                <span class="title-item">{{
                  $t("invitationsAndCoupons.amountRightReserved")
                }}</span>
                <tvos-int-input v-model="assetValue" jpy class="mb-9" />
              </div>
            </v-col>
          </v-row>
          <v-row class="px-10 list-item">
            <div style="width: 100%">
              <span class="title-item">{{ $t("common.remark") }}</span>
              <v-text-field dense />
            </div>
          </v-row>
        </v-card-text>
        <v-card-text class="mt-5">
          <v-row class="mt-1">
            <v-col :cols="6" class="d-flex justify-start"> </v-col>
            <v-col :cols="6" class="d-flex justify-end">
              <v-btn
                color="var(--bt__red)"
                class="mr-3 d-flex align-center btn_status white--text"
                @click="closeDialog"
              >
                <v-icon class="white--text"> mdi-close </v-icon>
                {{ $t("common.close") }}
              </v-btn>
              <v-btn
                class="white--text"
                color="var(--bt__blue)"
                @click="actionCreateNew()"
              >
                {{ $t("common.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { formatDate } from '@/constants/functions'
import { mapMutations, mapActions } from 'vuex'
import { FACLILITY_LIST } from '@/api/graphql/guestRoomDailyReport/create-booking.js'
import gql from 'graphql-tag'

export default {
  name: 'CreateInvitationAndCoupon',
  props: {
    listBookingTicketType: Object,
    showDialog: Boolean,
    invitation: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      selectFacility: true,
      listFac: [],
      statusFree: false,
      statusNotFree: true,
      listRoomType: [],
      facilityList: [],
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      holiday: false,
      assetValue: 0,
      cancelDeadline: null,
      bookingFromDays: null,
      bookingFromMonths: null,
      roomTypeIds: [],
      nightwear: false,
      toothbrush: false,
      bookCallCenter: false,
      bookSalesRep: false,
      razor: false,
      rentalItem: false,
      accommodationPrice: null,
      minFromDate: new Date().toISOString().substr(0, 10),
      minToDate: new Date().toISOString().substr(0, 10),
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      menuShowDate: false,
      menuShowTodate: false,
      nameTicket: '',
      numberOfTickets: null,
      invitationData: {
        title: this.invitation ? this.invitation.title : '',
        facility: this.invitation ? this.invitation.facility : '',
        roomType: this.invitation ? this.invitation.roomType : [],
        reservedPath: this.invitation ? this.invitation.reservedPath : [0, 0],
        dayOfWeek: this.invitation
          ? this.invitation.dayOfWeek
          : [0, 0, 0, 0, 0, 0, 0, 0],
        exclusionDay: this.invitation
          ? this.invitation.exclusionDay
          : new Date().toISOString().substr(0, 10),
        userOrNot: this.invitation ? this.invitation.userOrNot : [0, 0, 0, 0],
        amountUsed: this.invitation ? this.invitation.amountUsed : [0, 0],
        reservationStartDate: this.invitation
          ? this.invitation.reservationStartDate
          : new Date().toISOString().substr(0, 10),
        reservationPeriod: this.invitation
          ? this.invitation.reservationPeriod
          : ''
      },
      rules: {
        nameTicket: [
          (v) => !!v || this.$t('rules.isRequired'),
          (v) => v?.length < 256 || this.$t('rules.isInvalid')
        ],
        numberOfTickets: [(v) => !!v || this.$t('rules.isRequired'),
          (v) => Number.isInteger(parseInt(v)) || this.$t('facility.rentalList.rules.isNumber')],
        roomTypeIds: [(v) => v.length > 0 || this.$t('rules.isRequired')],
        fromDate: [(v) => !!v || this.$t('rules.isRequired'),
          (v) => v >= new Date().toISOString().substr(0, 10) || this.$t('rules.isInvalid')
        ],
        toDate: [(v) => !!v || this.$t('rules.isRequired')],
        excludingDay: [(v) => !!v || this.$t('rules.isRequired')],
        accommodationPrice: [(v) => !!v || this.$t('rules.isRequired')],
        cancelDeadline: [(v) => !!v || this.$t('rules.isRequired')],
        contractStartDate: [(v) => !!v || this.$t('rules.isRequired')],
        bookingFromDays: [(v) => typeof v === 'number' || this.$t('rules.isRequired')],
        bookingFromMonths: [(v) => typeof v === 'number' || this.$t('rules.isRequired')],
        assetValue: [(v) => !!v || this.$t('rules.isRequired')]
      },
      menuDate: [],
      listDate: [
        { id: 1, date: new Date().toISOString().substr(0, 10) },
        { id: 2, date: new Date().toISOString().substr(0, 10) }
      ],
      excludingDayMenu: false,
      excludingDay: [],
      contractStartDateMenu: false,
      contractStartDate: new Date().toISOString().substr(0, 10),
      taxIncludeFee: 0,
      amountFee: 0
    }
  },

  computed: {
    itemCancelDate () {
      const data = [...Array(100)].map((_, ind) => ({
        text: `宿泊日から${ind + 1}日前以降`,
        value: ind + 1
      }))
      return data
    },
    bookingFromDaysSelect () {
      return [...Array(101)].map((_, ind) => ({
        text: ind,
        value: ind
      }))
    },
    bookingFromMonthsSelect () {
      return [...Array(7)].map((_, ind) => ({
        text: ind,
        value: ind
      }))
    }
  },

  created () {
  },
  async mounted () {
    this.setToDate()
    await this.getFacilityList()
    await this.fetchData()
  },

  methods: {
    ...mapMutations({ setInvitationTicket: 'setInvitationTicket' }),
    ...mapActions({ actionCreate: 'createBookingTickets' }),

    fetchData () {
      if (this.listBookingTicketType && this.listBookingTicketType !== null) {
        this.listFac = []
        this.nameTicket = this.listBookingTicketType.name
        this.numberOfTickets = this.listBookingTicketType.computed.issuedCount
        for (let i = 0; i < this.listBookingTicketType.roomTypes.length; i++) {
          if (!this.listFac.includes(this.listBookingTicketType.roomTypes[i].roomType.facility.id)) {
            this.listFac.push(this.listBookingTicketType.roomTypes[i].roomType.facility.id)
          }
          this.roomTypeIds.push(this.listBookingTicketType.roomTypes[i].roomType.id)
        }
        if (this.listFac.length > 0) {
          this.selectFacility = false
          this.listRoomType = []
          for (let i = 0; i < this.facilityList.length; i++) {
            for (let j = 0; j < this.listFac.length; j++) {
              if (this.listFac[j] === this.facilityList[i].id) {
                this.listRoomType = this.listRoomType.concat(this.facilityList[i].roomTypes)
              }
            }
          }
        } else this.selectFacility = true
        for (let i = 0; i < this.listBookingTicketType.exceptDates.length; i++) {
          this.excludingDay.push(this.listBookingTicketType.exceptDates[i].exceptDate)
        }
        this.fromDate = this.listBookingTicketType.availableFromDate
        this.toDate = this.listBookingTicketType.availableToDate
        this.bookCallCenter = this.listBookingTicketType.bookCallCenter
        this.bookSalesRep = this.listBookingTicketType.bookSalesRep
        this.monday = this.listBookingTicketType.availableMonday
        this.tuesday = this.listBookingTicketType.availableTuesday
        this.wednesday = this.listBookingTicketType.availableWednesday
        this.thursday = this.listBookingTicketType.availableThursday
        this.friday = this.listBookingTicketType.availableFriday
        this.saturday = this.listBookingTicketType.availableSaturday
        this.nightwear = this.listBookingTicketType.nightwear
        this.toothbrush = this.listBookingTicketType.toothbrush
        this.razor = this.listBookingTicketType.razor
        this.rentalItem = this.listBookingTicketType.rentalItem
        this.accommodationPrice = this.listBookingTicketType.accommodationPrice
        this.cancelDeadline = this.listBookingTicketType.cancelDeadline
        this.contractStartDate = this.listBookingTicketType.bookingFromDate
        this.bookingFromDays = this.listBookingTicketType.bookingFromDays
        this.bookingFromMonths = this.listBookingTicketType.bookingFromMonths
        this.assetValue = this.listBookingTicketType.assetValue
      }
    },

    resetForm () {
      this.$refs.form.resetValidation()
      this.monday = false
      this.tuesday = false
      this.wednesday = false
      this.thursday = false
      this.friday = false
      this.saturday = false
      this.sunday = false
      this.holiday = false
      this.assetValue = 0
      this.cancelDeadline = null
      this.bookingFromDays = null
      this.bookingFromMonths = null
      this.roomTypeIds = null
      this.nightwear = false
      this.toothbrush = false
      this.exceptDates = []
      this.bookingFromDate = ''
      this.bookCallCenter = false
      this.bookSalesRep = false
      this.razor = false
      this.rentalItem = false
      this.accommodationPrice = 0
      this.fromDate = new Date().toISOString().substr(0, 10)
      this.toDate = new Date().toISOString().substr(0, 10)
      this.menuShowDate = false
      this.menuShowTodate = false
      this.nameTicket = ''
      this.numberOfTickets = null
      this.listRoomType = []
      this.listFac = []
      this.setToDate()
    },

    getFree (event) {
      this.statusFree = event
      this.statusNotFree = !event
      this.accommodationPrice = 0
    },

    getNotFree (event) {
      this.statusNotFree = event
      this.statusFree = !event
      this.accommodationPrice = 0
    },

    changeFromDate (event) {
      const result = new Date(event)
      const addRes = new Date(event).setDate(result.getDate() + 1)
      const date = new Date(addRes)
      this.minToDate = date.toISOString().substr(0, 10)
      this.toDate = date.toISOString().substr(0, 10)
    },
    setToDate () {
      const result = new Date()
      this.toDate = new Date().setDate(result.getDate() + 1)
      const date = new Date(this.toDate)
      this.toDate = date.toISOString().substr(0, 10)
      this.minToDate = date.toISOString().substr(0, 10)
    },

    formatDate,

    getSelectFacility (event) {
      if (event.length > 0) {
        this.selectFacility = false
      } else {
        this.selectFacility = true
      }
      this.listRoomType = []
      for (let i = 0; i < this.facilityList.length; i++) {
        for (let j = 0; j < event.length; j++) {
          if (event[j] === this.facilityList[i].id) {
            this.listRoomType = this.listRoomType.concat(this.facilityList[i].roomTypes)
          }
        }
      }
    },

    async getFacilityList () {
      await this.$apollo
        .query({
          query: gql`${FACLILITY_LIST}`
        })
        .then((data) => {
          this.facilityList = data.data.facilityList
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async actionCreateNew () {
      if (this.$refs.form.validate()) {
        const invitationTicket = {
          name: this.nameTicket,
          numberOfTickets: this.numberOfTickets,
          roomTypeIds: this.roomTypeIds,
          availableFromDate: this.fromDate,
          availableToDate: this.toDate,
          bookCallCenter: this.bookCallCenter,
          bookSalesRep: this.bookSalesRep,
          monday: this.monday,
          tuesday: this.tuesday,
          wednesday: this.wednesday,
          thursday: this.thursday,
          friday: this.friday,
          saturday: this.saturday,
          sunday: this.sunday,
          holiday: this.holiday,
          exceptDates: this.excludingDay,
          nightwear: this.nightwear,
          toothbrush: this.toothbrush,
          rentalItem: this.rentalItem,
          razor: this.razor,
          accommodationPrice: this.accommodationPrice,
          cancelDeadline: this.cancelDeadline,
          bookingFromDate: this.contractStartDate,
          bookingFromDays: this.bookingFromDays,
          bookingFromMonths: this.bookingFromMonths,
          assetValue: this.assetValue
        }
        this.setInvitationTicket({ invitationTicket: invitationTicket })
        await this.actionCreate()
        this.closeDialog()
        this.resetForm()
      }
    },

    closeDialog () {
      this.resetForm()
      this.$emit('close')
    }
  },

  components: {
  }
}
</script>

<style scoped lang="scss">
.disable {
  pointer-events: none;
}
.list-item::v-deep {
  div {
    font-size: 14px;
    span {
      &:first-child {
        font-size: 10px !important;
        font-weight: bold !important;
      }
      &.v-btn__content {
        font-size: 13px !important;
        font-weight: unset !important;
      }
    }
    .v-label {
      font-size: 14px;
    }
    .v-input--checkbox {
      margin: 0 0;
    }
  }
}

::v-deep {
  .v-input--selection-controls__input .v-label .theme--light {
    color: red !important
  }
  .custom-picker .v-size--small {
    color: #ffffff;
    border: solid 1px #000000;
  }
  .custom-picker .v-btn__content {
    color: #000000;
  }
  .custom-btn-picker .v-size--small {
    margin: 0 !important;
    width: 115px !important;
  }
  .list-item div span.v-btn__content {
    font-size: 10px !important;
  }
  .mdi-calendar-month::before {
    color: #333333;
  }
  .user-or-not .v-label {
    color: #000000;
  }
  .v-list-item__title {
    font-size: 14px !important;
  }
  .v-list-item__action {
    margin: 0;
  }
}
.size-text-notice {
  font-size: 10px;
}
.title-dialog {
  font-size: 20px;
  font-weight: bold !important;
  color: #000000;
}
.title-item {
  color: #212121;
}
</style>
